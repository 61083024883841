@import '~@gutsjs/guts-shared/dist/css/guts-styles.css';

html,
body {
  padding: 0;
  margin: 0;
  background: rgb(15, 15, 15);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --ff-sans-serif: 'Roboto', sans-serif;
  --ff-mono: 'Fira Mono', monospace;

  --fs-xl: clamp(3.5rem, 12vw + 1rem, 12rem);
  --fs-md: 30px;
  --fs-p: 20px;
}

@media screen and (max-width: 1440px) {
  :root {
    --fs-md: 26px;
    --fs-p: 18px;
  }
}

@media screen and (max-width: 968px) {
  :root {
    --fs-md: 24px;
    --fs-p: 17px;
  }
}

@media screen and (max-width: 768px) {
  :root {
    --fs-md: 22px;
    --fs-p: 15px;
  }
}

@media screen and (max-width: 468px) {
  :root {
    --fs-md: 20px;
    --fs-p: 14px;
  }
}
@media screen and (max-width: 368px) {
  :root {
    --fs-md: 18px;
    --fs-p: 13px;
  }
}

h3 {
  font-family: var(--ff-mono);
  font-weight: 400;
  font-size: var(--fs-md);
}

p {
  color: var(--guts-color-text);
  font-family: var(--ff-mono);
  font-weight: 400;
  font-size: var(--fs-p);
}

/* ----------------------------------------------
 * Generated by Animista on 2022-3-10 12:54:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tilt-in-fwd-bl
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-fwd-bl {
  0% {
    -webkit-transform: rotateY(-20deg) rotateX(-35deg) translate(-300px, 300px)
      skew(-35deg, 10deg);
    transform: rotateY(-20deg) rotateX(-35deg) translate(-300px, 300px)
      skew(-35deg, 10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-fwd-bl {
  0% {
    -webkit-transform: rotateY(-20deg) rotateX(-35deg) translate(-300px, 300px)
      skew(-35deg, 10deg);
    transform: rotateY(-20deg) rotateX(-35deg) translate(-300px, 300px)
      skew(-35deg, 10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}
