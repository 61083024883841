@import url("https://fonts.googleapis.com/css2?family=Fira+Mono:wght@400;700&family=Roboto:wght@400;700&display=swap");
.guts-scope h1,
.guts-scope h2,
.guts-scope h3,
.guts-scope h4,
.guts-scope h5,
.guts-scope h6,
.guts-scope p {
  margin: 0;
  padding: 0;
  line-height: 120%;
  font-family: "Roboto", sans-serif;
}
.guts-scope h1 {
  font-family: "Roboto", sans-serif;
  color: var(--guts-color-white);
  font-size: 36px;
  font-weight: 700;
  text-transform: capitalize;
}
.guts-scope h2 {
  font-family: "Fira Mono", monospace;
  color: var(--guts-color-text);
  font-size: 28px;
  font-weight: 400;
}
.guts-scope h3 {
  font-family: "Fira Mono", monospace;
  color: var(--guts-color-text);
  font-size: 24px;
  font-weight: 400;
}
.guts-scope h4 {
  font-family: "Fira Mono", monospace;
  color: var(--guts-color-text);
  font-size: 18px;
  font-weight: 400;
}
.guts-scope h4 small {
  font-size: 14px;
}
.guts-scope h5 {
  font-family: "Roboto", monospace;
  color: var(--guts-color-heading);
  font-size: 18px;
  font-weight: 700;
}
.guts-scope h6 {
  font-family: "Roboto", monospace;
  color: var(--guts-color-heading);
  font-size: 15px;
  font-weight: 400;
}
.guts-scope p {
  color: var(--guts-color-text);
  font-family: "Fira Mono", monospace;
}
.guts-scope .path-root-title {
  color: var(--guts-color-blue);
}
.guts-scope .path-root-title span {
  color: var(--guts-color-text);
}
.guts-scope .path-root-title .muted {
  opacity: 0.5;
}
.guts-scope .path-root-title.highlight-slug {
  color: rgba(98, 175, 239, 0.7);
}
.guts-scope .path-root-title.highlight-slug span {
  opacity: 0.5;
  color: var(--guts-color-text);
}
.guts-scope .path-root-title.highlight-slug .muted {
  opacity: 1;
}

:root {
  --guts-color-selected: rgb(107, 183, 250);
  --guts-color-white: rgb(239, 253, 255);
  --guts-color-light: rgb(216, 228, 230);
  --guts-color-light-shade: rgb(193, 206, 208);
  --guts-color-black: rgb(18, 18, 18);
  --guts-color-dark: rgb(28, 28, 28);
  --guts-color-dark-rgb: 28, 28, 28;
  --guts-color-dark-tint: rgb(38, 38, 38);
  --guts-color-dark-paper: #26292a;
  --guts-color-muted-text: rgb(96, 96, 96);
  --guts-color-medium: rgb(69, 69, 69);
  --guts-color-text: #abb2bf;
  --guts-color-heading: #c5d0d1;
  --guts-color-yellow: #ecbf6f;
  --guts-color-blue: #62afef;
  --guts-color-blue-rgb: 98, 175, 239;
  --guts-color-purple: #c678dd;
  --guts-color-purple-rgb: 198, 120, 221;
  --guts-color-orange: #e5c07b;
  --guts-color-orange-rgb: 229, 192, 123;
  --guts-color-green: #98c379;
  --guts-color-green-rgb: 152, 195, 121;
  --guts-color-red: #f06d6d;
}

/*# sourceMappingURL=guts-styles.css.map */

html,
body {
  padding: 0;
  margin: 0;
  background: rgb(15, 15, 15);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --ff-sans-serif: 'Roboto', sans-serif;
  --ff-mono: 'Fira Mono', monospace;

  --fs-xl: clamp(3.5rem, 12vw + 1rem, 12rem);
  --fs-md: 30px;
  --fs-p: 20px;
}

@media screen and (max-width: 1440px) {
  :root {
    --fs-md: 26px;
    --fs-p: 18px;
  }
}

@media screen and (max-width: 968px) {
  :root {
    --fs-md: 24px;
    --fs-p: 17px;
  }
}

@media screen and (max-width: 768px) {
  :root {
    --fs-md: 22px;
    --fs-p: 15px;
  }
}

@media screen and (max-width: 468px) {
  :root {
    --fs-md: 20px;
    --fs-p: 14px;
  }
}
@media screen and (max-width: 368px) {
  :root {
    --fs-md: 18px;
    --fs-p: 13px;
  }
}

h3 {
  font-family: var(--ff-mono);
  font-weight: 400;
  font-size: var(--fs-md);
}

p {
  color: var(--guts-color-text);
  font-family: var(--ff-mono);
  font-weight: 400;
  font-size: var(--fs-p);
}

/* ----------------------------------------------
 * Generated by Animista on 2022-3-10 12:54:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tilt-in-fwd-bl
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-fwd-bl {
  0% {
    transform: rotateY(-20deg) rotateX(-35deg) translate(-300px, 300px)
      skew(-35deg, 10deg);
    opacity: 0;
  }
  100% {
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-fwd-bl {
  0% {
    transform: rotateY(-20deg) rotateX(-35deg) translate(-300px, 300px)
      skew(-35deg, 10deg);
    opacity: 0;
  }
  100% {
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}

